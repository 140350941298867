import React,{useEffect,useRef} from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger);
}
const StickySLide = ({children}) => {
    const $contentImageWrapper = useRef(null)
    const $contentImagePin = useRef(null)
    const $spacerRef = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia()

        mm.add("(min-width: 1024px)", () => {
            const contentImages =
                $contentImageWrapper.current.querySelectorAll(".content-image")

            contentImages[0].classList.add("is-active")

            ScrollTrigger.create({
                trigger: $contentImageWrapper.current,
                // pin: $contentImagePin.current,
                start: "top top",
              
                onEnter: () => {},
                onLeave: () => {
                    contentImages[0].classList.remove("is-active")
                    const lastEl = contentImages[contentImages.length - 1]
                    lastEl.classList.add("is-active")
                 

                    // $contentImagePin.current.style.height = `${height}px`
                },

                onUpdate: self => {
                    const progress = Number(self.progress.toFixed(3))
                    const total = contentImages.length + 1
                    // console.log(progress)
                    contentImages.forEach((_, i) => {
                        const oldIndex = i
                        const newIndex = oldIndex + 1

                        if (
                            progress > oldIndex / total &&
                            progress < newIndex / total
                        ) {
                            contentImages[oldIndex].classList.add("is-active")
                            if (oldIndex === contentImages.length - 1) {
                                const lastEl = contentImages[oldIndex];
                                lastEl.classList.add("last");
                                const height = lastEl.offsetHeight;
                                $contentImagePin.current.style.height = `${height}px` 
                            }
                                
                            
                            if (self.direction === 1 && oldIndex > 0) {
                                contentImages[oldIndex - 1].classList.remove(
                                    "is-active"
                                )
                            } else if (
                                self.direction === -1 &&
                                newIndex < contentImages.length
                            ) {
                                contentImages[newIndex].classList.remove(
                                    "is-active"
                                )
                            }
                        }
                    })
                }
            })
        })

        return () => {
            mm.revert()
        }
    }, [])
  return (
    <div className="mt-20 pb-8" ref={$contentImageWrapper}>
    <div
        className="relative lg:sticky lg:top-[20vh] lg:h-screen"
        ref={$contentImagePin}
    >   {children}
        </div>
        <div ref={$spacerRef} className="lg:h-[400vh]"></div>
        </div>
  )
}

export default StickySLide