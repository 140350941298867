import React, { useRef, useEffect } from "react"

import Layout from "../../components/layout"
import HeroTwo from "../../components/Hero/HeroTwo"
import Overview from "../../components/CaseStudy/Overview"
import GremlinWorksBanner from "../../components/CaseStudy/GremlinWorkBanner"
import TitleContent from "../../components/TitleContent/TitleContent"
import Hello from "../../components/Hello/Hello"
import Newsletter from "../../components/Form/Newsletter"
import ContentImage from "../../components/ContentImage/ContentImage"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import { caseStudies } from "."
import ShowcaseSlider from "../../components/ShowcaseSlider"
import { StaticImage } from "gatsby-plugin-image"
import StickySLide from "../../components/StickySlide"
import { SEO } from "../../components/seo"

if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
const techs = [
    { src: "/case-study/next.svg" },
    { src: "/case-study/strapi.svg" },
    { src: "/case-study/netlify.svg" },
    { src: "/case-study/tailwind.svg" },
    { src: "/case-study/gsap.png" }
]

const heroData = {
    preheader: "Gremlin Works",
    title: "Empowering Global Bridging: Gremlin Works Case Study",
    fullWidth: true
}
const overview = {
    overview:
        "Welcome to the Gremlin Works case study. Gremlin Works, a comprehensive digital agency with bases in the UK and Taiwan, embarked on a journey to transcend geographical boundaries. Their goal? To seamlessly merge Western and Eastern brand messaging into highly converting digital experiences. ",
    services: ["Headless CMS", "Design", "Development", "SEO"],
    linkUrl: "https://gremlinworks.com.tw/"
}
const lhScores = [
    {
        title: "Performance",
        score: 99
    },
    {
        title: "Accessibility",
        score: 100
    },
    {
        title: "Best Practices",
        score: 95
    },
    {
        title: "SEO",
        score: 100
    }
]
const GremlinWorks = ({ location }) => {
    const context = {
        pageName: "SD | Case studies - Gremlin Works",
        pageUri: location.href
    }

    return (
        <Layout context={context}>
            <HeroTwo props={heroData} />
            <Overview {...overview} />
            <GremlinWorksBanner className={"mt-24"} />
            <TitleContent
                title="Bridging West and East Digitally for Gremlin Works "
                className="py-14 lg:py-24"
            >
                <p className="mb-4">
                    Saigon Digital collaborated closely with Gremlin Works to
                    craft a bespoke solution that transcends borders. Leveraging
                    our preferred tech stack, we seamlessly integrated Next.js
                    for a lightning-fast frontend and harnessed Tailwind UI for
                    adaptable styling. Complementing this, Strapi CMS empowered
                    effortless backend management.
                </p>
                <p className="mt-4">
                    Our top priority was creating a responsive, user-friendly UI
                    that harmonised with a flexible content editing system. To
                    breathe life into the website, we used GSAP to implement
                    creative animations and captivating on-page scrolling
                    effects.
                </p>
                <p className="mt-4">
                    The synergy of Next.js, Strapi, Tailwind, Vercel, Heroku,
                    Cloudinary, Postgres, HubSpot, and GreenSock JS (GSAP)
                    culminated in a digital masterpiece. This case study
                    underlines our ability to blend innovation, technology, and
                    aesthetics, resulting in a cohesive digital ecosystem for
                    Gremlin Works.
                </p>
                <h3 className="mt-8 text-lg font-semibold">
                    Technologies used:
                </h3>
                <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                    {techs.map(({ src }, index) => {
                        return (
                            <div class="group grid aspect-[160/78] place-items-center border py-2 px-6 transition-all duration-300 hover:shadow-light_shadow">
                                <img
                                    src={src}
                                    className="max-w-full object-contain grayscale transition-all duration-300 group-hover:grayscale-0"
                                    alt="logos"
                                />
                            </div>
                        )
                    })}
                </div>
            </TitleContent>
            <Hello
                link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                title="Optimised User-Experience with Google Lighthouse"
                message={"Want to learn how we can take your website to the next level? Stand out from your competition now. check out our "}
                className={"bg-[#f4f4f0]"}
                messageLinkTitle={
                    "JAMstack Versus Traditional Website Development guide!"
                }
                lhScores={lhScores}
            >
                <p className="mb-4">
                    Google Lighthouse is an open-source tool for website
                    performance, auditing pages for performance, accessibility,
                    SEO, Progressive Web App and Best Practices.
                </p>
                <p className="mb-4">
                    Using Google Lighthouse as a benchmark, we make sure that
                    Gremlin's website is performing flawlessly with excellent
                    scores.
                </p>
                <p>
                    Here is the performance results:{" "}
                    <a
                        href="/gremlinworks.com.tw.report.html"
                        target="_blank"
                        download
                        className="font-semibold text-primary-color underline"
                    >
                        Google Lighthouse Report
                    </a>
                </p>
            </Hello>
            <TitleContent title="The Challenge" className={"py-14 lg:py-24"}>
                <p>
                    With Gremlin Works' expertise in full-service solutions,
                    they shared their unique vision to position themselves as
                    one of the finest agencies in branding, marketing, and web
                    design. The project highly requires a multilingual,
                    SEO-optimised, and high-performance website, seamlessly
                    fusing technology and aesthetics for blazing performance.
                </p>
            </TitleContent>

            <ShowcaseSlider slides={slides} />

            <TitleContent title="The Solutions" className={"mt-28 mb-24"}>
                <p className="text-base text-[#1D2333]">
                    Saigon Digital embarked on this cross-cultural endeavour by
                    harnessing Next.JS, a robust React framework, to ensure
                    swift, SEO-friendly, and feature-rich web applications. To
                    provide a top-notch CMS experience, we incorporated Strapi,
                    a customisable and lightweight platform, allowing for
                    tailored content management while embracing seamless
                    editing.
                    <br />
                    &nbsp;
                    <br />
                    For the design, we hononed the client's vision, implementing
                    creative animations using the dynamic GreenSock JS (GSAP).
                    Form submissions found their ally in HubSpot, facilitating
                    smooth interactions for users. Additionally, we employed the
                    innovative Partytown library, utilising its web worker
                    technology to elevate the website's overall performance.
                </p>
            </TitleContent>
            <div className="container mb-16 grid gap-y-10 gap-x-[30px] md:grid-cols-2 lg:mb-24 lg:gap-x-24">
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:ml-5 ">
                    <img
                        src="/case-study/Gremlin-2-col-left.svg"
                        className="h-full w-full duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:mr-5 ">
                    <img
                        src="/case-study/Gremlin-2-col-right.svg"
                        className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
            </div>
            <Newsletter />
            <TitleContent title="Outcomes">
                <p>
                    Collaborated with Gremlin Works to orchestrate a bespoke
                    solution. Leveraging our preferred tech stack, we seamlessly
                    integrated Next.js for a lightning fast frontend & Tailwind
                    UI for adaptable styling. Complementing this, Strapi CMS
                    empowered effortless backend management.
                </p>{" "}
            </TitleContent>

            <StickySLide>
                <ContentImage
                    className="lg:opacity-100"
                    img={"/case-study/Gremlin-Outcome-1.png"}
                >
                    <h2 className="mb-4 text-2xl font-semibold">
                        Technologies in Harmony: Functionality and Form
                    </h2>

                    <ul className="list-disc pl-5">
                        <li>
                            Functionality-wise, our marriage of multilingual
                            capability, SEO optimisation, high performance, and
                            the agility of a Headless CMS resonated with the
                            client's aspirations.{" "}
                        </li>
                        <li>
                            Tailwind delivered adaptable styling, while Vercel,
                            Heroku, and Cloudinary facilitated impeccable
                            deployment and hosting. The database foundation lay
                            in Postgres, with every element tied together
                            seamlessly.{" "}
                        </li>
                    </ul>
                </ContentImage>
                <ContentImage img={"/case-study/Gremlin-Outcome-2.png"}>
                    <h2 className="mb-4 text-2xl font-semibold">
                        Result to Bridge Continents
                    </h2>

                    <ul className="list-disc pl-5">
                        <li>
                            {" "}
                            The collaborative synergy between Gremlin Works and
                            Saigon Digital bore fruit in a sophisticated website
                            that effortlessly transcends cultural boundaries.{" "}
                        </li>
                        <li>
                            The tailored design brought the client's vision to
                            life, while our meticulous technological
                            orchestration ensured performance, stability, and a
                            seamless user experience.{" "}
                        </li>
                        <li>
                            By utilising JAMstack architecture, Strapi, and
                            creative animations, we engineered an impeccable
                            online presence for Gremlin Works.
                        </li>
                    </ul>
                </ContentImage>
                <ContentImage img={"/case-study/Gremlin-Outcome-3.png"}>
                    <h2 className="mb-4 text-2xl font-semibold">
                        Monorepo: Our Architectural Triumph
                    </h2>

                    <p>
                        At Saigon Digital, we championed a monorepo
                        architecture, consolidating all front-end and back-end
                        codes in a unified hub. This approach not only
                        streamlined development but also exemplified our
                        commitment to holistic, efficient solutions.
                    </p>
                </ContentImage>
            </StickySLide>

            <TitleContent title={"The Results"} className={"mb-14"}>
                <p>
                    The Gremlin Works case exemplifies how Saigon Digital's
                    expertise enhances global businesses, bridging cultural
                    divides through seamless technology and captivating design.
                    This case study stands as a testament to our commitment to
                    delivering impactful digital transformations that transcend
                    borders.
                </p>{" "}
            </TitleContent>
            <div className=" flex min-h-[360px] items-center bg-white py-10 lg:max-h-[360px]  ">
                <div className="container mt-28  grid grid-cols-12 gap-y-12 lg:mt-28">
                    <div className="col-span-full flex flex-col items-start gap-5 md:col-span-6 lg:col-span-7">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={58}
                            height={59}
                            viewBox="0 0 58 59"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_654_5509)">
                                <path
                                    d="M0.756228 58.9841L0.756226 16.6365C0.756225 7.86597 7.86609 0.756111 16.6366 0.756111L57.4718 0.756104"
                                    stroke="black"
                                    strokeWidth="0.756208"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_654_5509">
                                    <rect
                                        width="57.4718"
                                        height="58.9842"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>

                        <h2 className="max-w-[650px] text-xl font-semibold text-black lg:text-[32px] lg:leading-[45px]">
                            We’ve compiled an overview of the <br /> basic
                            services offered by brand design into our world.
                        </h2>
                    </div>
                    <div className="col-span-full flex md:col-span-6 lg:col-span-5 lg:justify-start">
                        <img
                            src="/case-study/gremlin/result.svg"
                            alt="gremlin logo"
                            className="h-full max-h-[220px] w-full max-w-[430px] "
                        />
                    </div>
                </div>
            </div>
            <div className=" mt-14 lg:mt-[64px]">
                <StaticTestimonial
                    title={"Thoughts from the Gremlin Work"}
                    content={
                        <p>
                            We've been working with Nick and the team at Saigon
                            for a while now. We keep coming back to them because
                            we know that projects will be handled professionally
                            and that we can trust them to do a standup job
                            without trying to cut corners or overcharge. Having
                            worked in this industry for a while now, I can say
                            it's pretty rare to find a quality partner like this
                            who can handle jobs smoothly and with good comms.
                        </p>
                    }
                    author={"Dylan Porter"}
                    position={"Taiwan Country Manager at Gremlin Works"}
                />
            </div>
            <ShowCase data={caseStudies} />
        </Layout>
    )
}

const slides = [
    {
        component: (
            <div className=" flex min-h-[50vh] items-center bg-black py-10 lg:max-h-[50vh]  ">
                <div className="container grid h-full  grid-cols-12 gap-y-5">
                    <div className="col-span-full flex items-center md:col-span-6 lg:col-span-7">
                        <h2 className="max-w-[583px] text-xl font-semibold text-white lg:text-[32px] lg:leading-[45px]">
                            Taipei Digital Marketing agency for SEO, Social &
                            Advertising
                        </h2>
                    </div>
                    <div className="col-span-full  flex items-center md:col-span-6 lg:col-span-5 lg:justify-end">
                        <img
                            src="/case-study/gremlin-eye.svg"
                            alt="gremlin logo"
                            className=""
                        />
                    </div>
                </div>
            </div>
        )
    },
    {
        component: (
            <div className=" relative flex min-h-[50vh]  items-center overflow-hidden bg-[#D9D9D9] py-10 lg:max-h-[50vh]   ">
                <img
                    src="/case-study/gremlin/mountain.svg"
                    className="absolute bottom-0  right-0 z-0 h-auto  w-[110%] lg:-bottom-[20px]"
                />
                <div className="container my-auto grid grid-cols-12 gap-y-5">
                    <div className="relative z-10 col-span-full flex items-center md:col-span-6 lg:col-span-7">
                        <h2 className="z-5 max-w-[583px] text-xl font-semibold text-black lg:text-[32px] lg:leading-[45px]">
                            Gremlin Works Digital Marketing Success Stories
                        </h2>
                    </div>
                    <div className="col-span-full flex  items-center md:col-span-6 lg:col-span-5 lg:justify-start">
                        <img
                            src="/case-study/gremlin/showcase-2.svg"
                            alt="gremlin logo"
                            className="relative  z-10"
                        />
                    </div>
                </div>
            </div>
        )
    },
    {
        component: (
            <div className=" flex min-h-[50vh] items-center bg-[#D9D9D9] py-10 lg:max-h-[50vh]  ">
                <div className="container grid  grid-cols-12 gap-y-5">
                    <div className="col-span-full flex items-center md:col-span-6 lg:col-span-7">
                        <h2 className="max-w-[583px] text-xl font-semibold text-black lg:text-[32px] lg:leading-[45px]">
                            By implementing SEO optimization strategies, you can
                            attract more valuable traffic
                        </h2>
                    </div>
                    <div className="col-span-full flex md:col-span-6 lg:col-span-5 lg:justify-start">
                        <img
                            src="/case-study/gremlin/showcase-3.svg"
                            alt="gremlin logo"
                            className=" "
                        />
                    </div>
                </div>
            </div>
        )
    }
]

export default GremlinWorks


export const Head = () => (
    <SEO
        title={"Gremlin Works | Saigon Digital Case Study"}
        description={
            "Building a JAMstack Experience with and blazing fast digital presence for GremlinWorks"
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
