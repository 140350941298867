import React,{useEffect, useRef} from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger);
}
const ShowcaseSlider = ({slides,className}) => {
    const tlDefaults = {
        ease: "slow.inOut",
        duration: 1.25
    }
    const $slider = useRef(null);
    const $progress = useRef(null);
    const currentSlide = useRef(undefined)
    const alreadyEntered = useRef(false);
    const next = useRef(0)
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide.current !== undefined) {
            }
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])
    // Slides a section in on scroll down
    function slideIn() {
        const outers = gsap.utils.toArray(".slider-outer")
        const inners = gsap.utils.toArray(".slider-inner")
        const images = gsap.utils.toArray(".slider-image")
        const texts = gsap.utils.toArray(".slider-text")
        console.log("slide in");
        // The first time this function runs, currentSlide is undefined
        const tl = gsap.timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
                currentSlide.current = next.current
                next.current = currentSlide.current + 1
                if (next.current > slides.length - 1) {
                    currentSlide.current = slides.length - 1
                    next.current = 0
                }

                const timeout = setTimeout(() => {
                    slideIn()
                }, 2000)
            }
        })

        tl.to(outers[next.current], { yPercent: 0 }, 0)
            .to(inners[next.current], { yPercent: 0 }, 0)
            .to(images[next?.current], { yPercent: 0, scale: 1 }, 0)
            .to(
                $progress.current,
                { scaleX: (next.current + 1) / slides.length },
                0
            )
            .fromTo(
                texts[next?.current],
                { y: 40, opacity: 0 },
                { y: 0, opacity: 1 },
                0
            )

        if (currentSlide?.current !== undefined) {
            tl.to(outers[currentSlide.current], { yPercent: -100 }, 0)
                .to(inners[currentSlide.current], { yPercent: 100 }, 0)
                .to(
                    images[currentSlide.current],
                    { scale: 1.2, yPercent: -15 },
                    0
                )
                .to(
                    texts[currentSlide?.current],
                    { y: -40, opacity: 0, duration: 0.8 },
                    0
                )

            tl.add(
                gsap
                    .timeline()
                    .set(outers[currentSlide?.current], {
                        yPercent: 100
                    })
                    .set(inners[currentSlide?.current], {
                        yPercent: -100
                    })
                    .set(images[currentSlide?.current], {
                        yPercent: 15,
                        scale: 1.3
                    })
            )
        }

        tl.play(0)
    }
    useEffect(() => {
        const ctx = gsap.context(() => {
            const outers = gsap.utils.toArray(".slider-outer")
            const inners = gsap.utils.toArray(".slider-inner")
            const images = gsap.utils.toArray(".slider-image")

            gsap.set(outers, { yPercent: 100 })
            gsap.set(inners, { yPercent: -100 })
            gsap.set(images, { scale: 1.3, yPercent: 15 })
            gsap.set($progress.current, { scaleX: 0 })

            ScrollTrigger.create({
                trigger: $slider.current,
               
                start: "top +50%",
             
                // markers: true,
                onLeave: () => {
                    console.log("leaving")
                },
                onEnter: () => {
                    if (!alreadyEntered.current) {
                        slideIn()

                        gsap.fromTo(
                            ".slider-logo",
                            { autoAlpha: 0 },
                            { autoAlpha: 1, duration: 1.5, delay: 1 }
                        )
                    }

                    alreadyEntered.current = true
                }
            })
        })

        return () => {
            ctx.revert()
        }
    }, [])

  return (
   
    <div className={`relative showcase-slide min-h-[50vh] h-auto flex-1 overflow-hidden ${className}`}>
                        <div className="absolute h-full inset-0">
                            <div
                                className="mandala-slider relative h-full w-full overflow-hidden will-change-transform"
                                ref={$slider}
                            >
                                {slides && slides.map((image, index) => {
                                    const ImageComponent = image.component
                                    return (
                                        <div
                                            className="slider-wrapper absolute inset-0"
                                            style={{
                                                zIndex: 10 - index
                                            }}
                                        >
                                            <div className="slider-outer relative h-full  w-full overflow-hidden will-change-transform">
                                                <div className="slider-inner absolute inset-0 will-change-transform">
                                                    <div
                                                        className={`slider-image relative h-full w-full bg-cover bg-center will-change-transform`}
                                                    >
                                                        {ImageComponent}
                                                      
                                                    </div>
                                                </div>
                                            </div>

                                           
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div
                            className="absolute bottom-0 left-0 h-1 w-full origin-left bg-white"
                            ref={$progress}
                        ></div>
                    </div>
  )
}

export default ShowcaseSlider