import Blob from "@images/blobanimation.svg"
import React, { useEffect } from "react"
import { useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.section`
    background: linear-gradient(91deg, #180f7d 0%, #27007a 100%);
`

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}
function GremlinWorksBanner({ className, startColor, endColor, children }) {
    const $wrapper = useRef(null)
    const $img1 = useRef(null)
    const $img2 = useRef(null)
    const $img3 = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: $wrapper.current,
                once: true,
               
                start: "top bottom-=50%",
                end: "top bottom-=50%"
            }
        })
        mm.add("(min-width: 1024px)", () => {
            tl.from($img1.current, {
                y: 60,
                opacity: 0,
                stagger: 0.5
            }).from(
                $img3.current,
                {
                    y: 60,
                    opacity: 0,
                    duration: 0.5,
                    ease: "back"
                },
                0
            ).from(
                $img2.current,
                {
                    opacity:0,
                    duration:0.5
                }
            )

            gsap.fromTo(
                ".banner-image",
                { yPercent: -55 },
                {
                    yPercent: -45,
                    scrollTrigger: {
                        trigger: $wrapper.current,
                        scrub: 0.8,

                       
                        start: "top bottom-=20%",
                        end: "bottom+=100% top"
                    },
                    duration: 1,
                    ease: "none"
                }
            )
        })

        return () => {
            mm.revert()
        }
    }, [])
    return (
        <div
            $startColor={startColor}
            $endColor={endColor}
            className={`relative min-h-[50vh] ${className} bg-[#F5F5F5] overflow-y-hidden`}
            ref={$wrapper}
        >
            <img src="/case-study/gremlin-banner.png" alt="" className="absolute z-0 inset-0 w-full h-full" />
            
                <div className="container">

                <div className=" h-full  h-full min-h-[50vh]  grid grid-cols-12 gap-6 text-center sm:items-start sm:justify-center sm:text-left md:max-w-none h-full">

                  <div className="hidden lg:flex h-full flex-col items-start justify-end col-span-4  ">
                  <img ref={$img1} src="/case-study/gremlin-banner-left.png" className="w-[55%] lg:w-[90%] z-10 object-cover"/> 
                  </div>
                  <div className="col-span-full  lg:col-span-4 lg:col-start-5 flex flex-col h-full items-center justify-end">
                    <img ref={$img2} src="/case-study/gremlin-works-center.png" className="object-cover z-10 w-full xl:w-[120%] mix-blend-multiply"/>
                  </div>
                  <div className="hidden lg:flex h-full pr-5 flex-col items-end justify-start col-span-4   ">
                  <img ref={$img3} src="/case-study/gremlin-banner-right.png" className="w-[75%]  z-10 object-cover"/> 
                  </div>
                </div>
                </div>

                {children}
               
            </div>
    
    )
}

export default GremlinWorksBanner

